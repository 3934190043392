import { Link } from '@remix-run/react'
import moment from 'moment'
import { getUserImgSrc } from '#app/utils/misc.tsx'
import { Badge } from './ui/badge.tsx'

type Props = {
	id: string
	createdAt: string
	updatedAt: string
	subject: string | null
	content: string
	userId: string
	user: any
}

const Post = ({ createdAt, content, user, subject, id }: Props) => {
	const authorName = `${user.firstName} ${user.lastName}` ?? ''
	const postTime = moment(createdAt)

	// @ts-ignore
	const isAdmin = !!user.roles.some(role => role.name === 'admin')

	return (
		<article className="my-2 rounded-lg bg-muted px-6 pb-6 text-base dark:bg-gray-900 sm:h-full">
			<div className="mb-2 flex items-center justify-between pt-6">
				<div className="flex w-full justify-between">
					<Link to={`/users/${user.username}`}>
						<p className="mr-3 inline-flex items-center text-sm font-semibold text-gray-900 dark:text-white">
							<img
								className="mr-2 h-10 w-10 rounded-full"
								src={getUserImgSrc(user.image?.id)}
								alt={authorName}
							/>
							<span className="flex flex-col">
								<span>
									{authorName}{' '}
									{isAdmin && (
										<Badge variant={'iagtGold'} className="ml-1">
											Organizer
										</Badge>
									)}
								</span>
								<span className="text-xs text-gray-500 dark:text-gray-400 sm:text-sm">
									{postTime.format('MMM Do @ LT')}
								</span>
							</span>
						</p>
					</Link>
				</div>
			</div>
			{subject && (
				<p className="text-sm font-bold text-gray-900 dark:text-gray-400">
					{subject}
				</p>
			)}
			<p className="text-sm text-gray-500 dark:text-gray-400">{content}</p>
		</article>
	)
}

export default Post
